import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMeasure } from 'react-use';
import { orderBy } from 'lodash';

import {
  faBuilding,
  faBolt,
  faRuler,
  faDollarSign,
  faCalculator,
} from '@fortawesome/free-solid-svg-icons';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Scatter,
  Rectangle,
} from 'recharts';

import WidgetInfo from '../../components/widgets/WidgetInfo';
import ActivePiechart from '../../components/ActivePieChart';
import CHARTCOLORS from '../../helpers/chartColors';
import BuildingMissingBenchmarkModal from '../../components/modals/BuildingMissingBenchmarkModal';
import formatValue from '../../helpers/chartFormatter';
import CheckResources from './CheckResources';
import GraphActionContainer from '../../components/chartElements/GraphActionContainer';
import ChartDownload from '../../components/chartElements/Actions/ChartDownload';
import ChartToggle from '../../components/chartElements/Actions/ChartToggle';
import ChartInfo from '../../components/chartElements/Actions/ChartInfo';
import ChartWarning from '../../components/chartElements/Actions/ChartWarning';

const styles = {
  label: {
    paddingLeft: '3px',
  },
  barChartContainer: {
    width: '100%',
    maxHeight: '25rem',
    paddingRight: '3rem',
    justifyContent: 'flex-end',
    overflowY: 'auto',
    overflowX: 'visible',
    position: 'relative',
    zIndex: 10,
  },
  col_widget_style: {
    minHeight: '120px',
  },
};

const DEFAULT_INFO = {
  buildings: 0,
  area: 0,
  total_cost: 0,
  total_usage: 0,
  cost_eui: 0,
  usage_eui: 0,
};

const containerId = 'et-building-bar-container';

const units = { cost: '$', usage: 'kbtu' };
const upper_types = { cost: 'Cost', usage: 'Energy' };

// Shortens building name label if too long
const applyBarDataLabels = (barData, barChartContainerWidth) => {
  const characterWidth = 10; // assume characters are 10px wide
  const labelAreaWidth = barChartContainerWidth * 0.2;
  return barData.map((data) => ({
    ...data,
    label:
      data.name.length * characterWidth > labelAreaWidth
        ? `${data.name.substring(0, labelAreaWidth / characterWidth)}...`
        : data.name,
  }));
};

// Provides height of and distance between the first two bars
// Used for positioning the custom benchmark scatter elements
const useBarPosition = () => {
  const [height, setHeight] = useState(0);
  const [y1, sety1] = useState(0);
  const [y2, sety2] = useState(0);

  const ref1 = useCallback((node) => {
    if (node !== null) {
      const { height, y } = node.props.background;
      setHeight(height);
      sety1(y);
    }
  }, []);

  const ref2 = useCallback((node) => {
    if (node !== null) {
      const { y } = node.props.background;
      sety2(y);
    }
  }, []);

  return [ref1, ref2, { height, y: y1, yDelta: y2 - y1 }];
};

export default function UtilitySummary(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const { dashboardData, organization, activeIds } = deferredProps;
  const [chartType1, setChartType1] = useState('usage');
  const [chartType2, setChartType2] = useState('usage');
  const [pieData, setPieData] = useState([]);
  const [buildingData, setBuildingData] = useState({});

  const [benchmarkMissing, setBenchmarkMissing] = useState([]);

  const [sortedBarData, setSortedBarData] = useState([]);
  const [sortFilter, setSortFilter] = useState(0);
  const [showBuildingNames, setShowBuildingNames] = useState(true);

  const [infoData, setInfoData] = useState({ ...DEFAULT_INFO });

  const [showMissingModal, setShowMissingModal] = useState(false);

  const [barChartContainerRef, { width: barsWidth }] = useMeasure();
  const [barChartContainerWidth, setBarChartContainerWidth] = useState(0);
  const [barRef1, barRef2, { height, y, yDelta }] = useBarPosition();

  const [chartBarDownloadHeaders, setChartBarDownloadHeaders] = useState([]);
  const [chartBarDownloadKeys, setChartBarDownloadKeys] = useState([]);

  useEffect(() => {
    setBarChartContainerWidth(barsWidth);
  }, [barsWidth]);

  useEffect(() => {
    if (Object.keys(dashboardData).length) {
      setBuildingData(setBuildingColors(dashboardData['building_info']));
      setBenchmarkMissing(dashboardData['benchmark_missing']);
    } else {
      setBuildingData({});
      setBenchmarkMissing([]);
    }
  }, [dashboardData]);

  const convertToChartForm = useCallback(
    (dashboardData, activeIds, chartType) => {
      let local_ids = [];
      activeIds.forEach((bid) => {
        if (bid in dashboardData['building_totals']) {
          local_ids.push(bid);
        }
      });
      return local_ids.map((bid) => {
        if (chartType === 'usageEUI') {
          return {
            id: parseInt(bid),
            name: dashboardData['building_info'][bid]['name'],
            value: dashboardData['building_totals'][bid][chartType],
            benchmark: dashboardData['benchmark_data'][bid],
            percentage:
              ((dashboardData['building_totals'][bid][chartType] -
                dashboardData['benchmark_data'][bid]) /
                dashboardData['benchmark_data'][bid]) *
              100,
          };
        } else {
          return {
            id: parseInt(bid),
            name: dashboardData['building_info'][bid]['name'],
            value: dashboardData['building_totals'][bid][chartType],
          };
        }
      });
    },
    []
  );

  const setBuildingColors = (building_info) => {
    Object.keys(building_info).forEach((building, index) => {
      building_info[building]['color'] =
        CHARTCOLORS[index % CHARTCOLORS.length];
    });
    return building_info;
  };

  useEffect(() => {
    if (Object.keys(dashboardData).length) {
      setPieData(convertToChartForm(dashboardData, activeIds, chartType1));
    } else {
      setPieData([]);
    }
  }, [dashboardData, chartType1, convertToChartForm, activeIds]);

  useEffect(() => {
    if (Object.keys(dashboardData).length) {
      let sorted_data = [];
      let new_sorted_data = [];
      //order date
      sorted_data = orderBy(
        convertToChartForm(dashboardData, activeIds, chartType2 + 'EUI'),
        ['value'],
        sortFilter === 0 ? ['asc'] : ['desc']
      );
      //remove empty points
      sorted_data.forEach((point) => {
        if (point['value'] !== 0) {
          new_sorted_data.push(point);
        }
      });
      setSortedBarData(new_sorted_data);
    } else {
      setSortedBarData([]);
    }
  }, [dashboardData, sortFilter, chartType2, convertToChartForm, activeIds]);

  const BarShape = (props) => {
    const { index } = props;
    const barRef = index === 0 ? barRef1 : index === 1 ? barRef2 : null;
    return <Rectangle ref={barRef} {...props} />;
  };

  const BenchmarkShape = ({ id, payload, x }) => {
    const index = sortedBarData.findIndex((bldg) => bldg.id === id);
    const style =
      payload.benchmark > payload.value
        ? { fill: buildingData[payload.id].color, opacity: '0.6' }
        : {};
    return (
      <svg>
        <rect
          y={y + yDelta * index}
          x={x + 2}
          height={height}
          width={2}
          {...style}
        />
      </svg>
    );
  };

  useEffect(() => {
    let headers = ['Building'];
    let keys = ['name', 'value'];
    if (chartType2 === 'usage') {
      headers = headers.concat(['EUI', 'Benchmark', 'Percent']);
      keys = keys.concat(['benchmark', 'percentage']);
    } else {
      headers.push('Cost Intensity');
    }
    setChartBarDownloadHeaders(headers);
    setChartBarDownloadKeys(keys);
  }, [chartType2]);

  useEffect(() => {
    if (Object.keys(dashboardData).length) {
      let count = 0;
      let area = 0;
      let usage = 0;
      let cost = 0;
      let usage_eui = 0;
      let cost_eui = 0;
      activeIds.forEach((id) => {
        if (id in dashboardData['building_totals']) {
          count += 1;
          usage += dashboardData['building_totals'][id]['usage'];
          cost += dashboardData['building_totals'][id]['cost'];
          if (
            id in dashboardData['building_info'] &&
            dashboardData['building_info'][id]['square_footage'] &&
            dashboardData['building_info'][id]['square_footage'] > 0
          ) {
            area += dashboardData['building_info'][id]['square_footage'];
            usage_eui += dashboardData['building_totals'][id]['usage'];
            cost_eui += dashboardData['building_totals'][id]['cost'];
          }
        }
      });
      if (area > 0) {
        usage_eui /= area;
        cost_eui /= area;
      }
      setInfoData({
        buildings: count,
        area: area,
        total_cost: cost,
        total_usage: usage,
        cost_eui: cost_eui,
        usage_eui: usage_eui,
      });
    } else {
      setInfoData({ ...DEFAULT_INFO });
    }
  }, [activeIds, dashboardData]);

  return (
    <>
      <CheckResources
        organization={organization}
        dashboardData={dashboardData}
      />

      <Row style={{ marginTop: '1em' }}>
        <Col md={7}>
          <Row>
            <Col md={6} style={styles.col_widget_style}>
              <WidgetInfo
                title={'Total Buildings'}
                value={infoData['buildings']}
                icon={faBuilding}
                iconColor={CHARTCOLORS[2]}
              />
            </Col>
            <Col md={6} style={styles.col_widget_style}>
              <WidgetInfo
                title={'Total Area (ft²)'}
                value={formatValue(infoData['area'])}
                icon={faRuler}
                iconColor={CHARTCOLORS[1]}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '1em' }}>
            <Col md={6} style={styles.col_widget_style}>
              <WidgetInfo
                title={
                  '12 Month ' +
                  upper_types[chartType1] +
                  (chartType1 === 'cost' ? '' : ' (kbtu)')
                }
                value={formatValue(
                  infoData['total_' + chartType1],
                  chartType1 === 'cost' ? '$' : ''
                )}
                icon={chartType1 === 'cost' ? faDollarSign : faBolt}
                iconColor={chartType1 === 'cost' ? CHARTCOLORS[0] : '#FFFF00'}
              />
            </Col>
            <Col md={6} style={styles.col_widget_style}>
              <WidgetInfo
                title={
                  'Average ' +
                  upper_types[chartType1] +
                  '/ft²' +
                  (chartType1 === 'cost' ? '' : ' (kbtu)')
                }
                value={formatValue(
                  infoData[chartType1 + '_eui'],
                  chartType1 === 'cost' ? '$' : ''
                )}
                icon={faCalculator}
                iconColor={CHARTCOLORS[5]}
              />
            </Col>
          </Row>
        </Col>
        <Col md={5} style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            <h5 style={{ display: 'inline' }}>
              {upper_types[chartType1] + ' Use Breakdown'}
            </h5>
            <div>
              <ActivePiechart data={pieData} unit={units[chartType1]} />
            </div>
          </div>
        </Col>
        <GraphActionContainer customStyle={{ marginTop: '0.5em' }}>
          <ChartToggle
            onClick={() => {
              chartType1 === 'usage'
                ? setChartType1('cost')
                : setChartType1('usage');
            }}
            state={chartType1 === 'usage' ? 0 : 1}
            title='Toggle Dashboard Type'
          />
          <ChartDownload
            organization_id={organization.id}
            data={pieData}
            headers={['Building', 'Value']}
            keys={['name', 'value']}
            chart_type={'pie'}
          />
        </GraphActionContainer>
      </Row>
      <Row style={{ marginTop: '2em' }}>
        <Col style={{ marginLeft: '1rem' }}>
          <Row
            style={{ justifyContent: 'space-between', paddingRight: '1rem' }}
          >
            <h5
              style={{ display: 'inline', marginLeft: '1em' }}
            >{`${upper_types[chartType2]} Performance By Building`}</h5>
          </Row>
        </Col>
      </Row>
      {sortedBarData.length > 0 && (
        <Row
          id={containerId}
          ref={barChartContainerRef}
          style={styles.barChartContainer}
        >
          <ResponsiveContainer
            width={showBuildingNames ? '86%' : '100%'}
            height={Math.max(50 + sortedBarData.length * 90, 180)}
            debounce={300}
          >
            <ComposedChart
              data={applyBarDataLabels(sortedBarData, barChartContainerWidth)}
              layout='vertical'
              margin={{
                top: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis
                type='number'
                tickFormatter={(tick) => tick.toLocaleString()}
                orientation='top'
                interval={'preserveEnd'}
              />
              <XAxis
                xAxisId='axis2'
                width={0}
                orientation='bottom'
                style={{ display: 'none' }}
              />
              <YAxis type='category' dataKey='label' tick={showBuildingNames} />
              <Tooltip />
              <Bar
                dataKey='value'
                barSize={30}
                unit={' ' + units[chartType2] + '/ft²'}
                formatter={(value) =>
                  value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }
                shape={(props) => <BarShape {...props} />}
              >
                {sortedBarData.map((data, index) => (
                  <Cell
                    fill={buildingData[data.id].color}
                    key={`cell-${index}`}
                  />
                ))}
              </Bar>
              <Scatter
                dataKey='benchmark'
                shape={(props) => <BenchmarkShape {...props} />}
                unit={' ' + units[chartType2] + '/ft²'}
                formatter={(value) =>
                  value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }
              />
              <Scatter
                dataKey='percentage'
                xAxisId='axis2'
                style={{ display: 'none' }}
                unit={'%'}
                formatter={(value) =>
                  value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Row>
      )}
      <Row>
        <GraphActionContainer customStyle={{ marginTop: '1em' }}>
          {chartType2 === 'usage' &&
            dashboardData['benchmark_survey'] &&
            [0, 1].includes(dashboardData['benchmark_survey']) && (
              <ChartInfo
                href='https://www.eia.gov/consumption/commercial/about.php'
                title='About CBECS'
              />
            )}
          {chartType2 === 'usage' &&
            dashboardData['benchmark_survey'] &&
            [2].includes(dashboardData['benchmark_survey']) && (
              <ChartInfo
                href='https://buildings.lbl.gov/cbs/bpd'
                title='About BPD'
              />
            )}
          {chartType2 === 'usage' && benchmarkMissing.length > 0 && (
            <ChartWarning
              title='View missing building types'
              onClick={() => setShowMissingModal(true)}
            />
          )}

          <ChartToggle
            onClick={() => {
              chartType2 === 'usage'
                ? setChartType2('cost')
                : setChartType2('usage');
            }}
            state={chartType2 === 'usage' ? 0 : 1}
            title='Toggle Dashboard Type'
          />
          <ChartToggle
            onClick={() =>
              sortFilter === 0 ? setSortFilter(1) : setSortFilter(0)
            }
            title='Toggle sort'
            state={sortFilter}
            variant={1}
          />
          <ChartToggle
            onClick={() => setShowBuildingNames(!showBuildingNames)}
            title='Toggle building names'
            state={showBuildingNames ? 0 : 1}
            variant={2}
          />
          <ChartDownload
            organization_id={organization.id}
            data={sortedBarData}
            headers={chartBarDownloadHeaders}
            keys={chartBarDownloadKeys}
            chart_type={'bar'}
          />
        </GraphActionContainer>
      </Row>

      <BuildingMissingBenchmarkModal
        benchmarkMissing={benchmarkMissing}
        buildingData={buildingData}
        org_id={organization.id}
        show={showMissingModal}
        onHide={() => setShowMissingModal(false)}
      />
    </>
  );
}
